<template>
  <b-col md="6" lg="4" class="evento-card">
    <b-card
      img-top
      no-body
      :style="{
        '--background-color-tertiary': config.disenio.datos.color_terciario,
        '--color-secondary': config.disenio.datos.color_secundario,
      }"
    >
      <div class="text-center">
        <router-link :to="'/evento/' + eventoData.entrada.url">
          <div
            v-if="eventoData.entrada.imagen"
            class="box-imagen box-imagen1 md-round"
          >
            <b-img-lazy
              :src="eventoData.entrada.imagen"
              :alt="eventoData.entrada.titulo"
              v-if="eventoData.entrada.imagen"
              center
              fluid
              class="md-round"
            />
          </div>
        </router-link>
      </div>

      <!-- body -->
      <b-card-body class="p-0 m-0">
        <b-card-title class="p-0 mt-3 mb-3">
          <router-link
            :to="'/evento/' + eventoData.entrada.url"
            class="text-secondary"
            :style="{
              '--color-secondary': config.disenio.datos.color_secundario,
            }"
          >
            {{ eventoData.entrada.titulo }}
          </router-link>
        </b-card-title>
        <!-- <b-card-text>
          <div v-html="eventoData.entrada.descripcion"></div>
        </b-card-text> -->
      </b-card-body>
      <b-media no-body>
        <b-media-aside class="me-1">
          <b-avatar rounded variant="primary" size="40">
            <feather-icon icon="CalendarIcon" size="20" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ eventoData.fecha }}
          </h6>
          <small v-if="eventoData.hora_inicio">
            {{ eventoData.hora_inicio }} a {{ eventoData.hora_final }}</small
          >
          <small v-else> Todo el Dia </small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="me-1">
          <b-avatar rounded variant="primary" size="40">
            <feather-icon icon="MapPinIcon" size="20" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ eventoData.ciudad }}
          </h6>
          <small>
            {{ eventoData.nombre_lugar }}, {{ eventoData.direccion }}
          </small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="me-1">
          <b-avatar rounded variant="primary" size="40">
            <feather-icon icon="PhoneIcon" size="20" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ eventoData.telefono }}
          </h6>
          <span>&nbsp;</span>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="me-1">
          <b-avatar rounded variant="primary" size="40">
            <feather-icon icon="UsersIcon" size="20" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ eventoData.nombre_organizador }}
          </h6>
          <small>
            {{ eventoData.telefono_organizador }}
          </small>
        </b-media-body>
      </b-media>

      <!-- kitchen sink link -->
      <!-- <b-list-group flush>
        <b-list-group-item>
          <strong>Fecha:&nbsp; </strong>{{ eventoData.fecha }}
        </b-list-group-item>
        <b-list-group-item v-if="eventoData.hora_inicio">
          <strong>Hora:&nbsp;</strong>{{ eventoData.hora_inicio }} a
          {{ eventoData.hora_final }}
        </b-list-group-item>
        <b-list-group-item>
          <strong>Lugar:&nbsp;</strong>{{ eventoData.nombre_lugar }}
        </b-list-group-item>
        <b-list-group-item>
          <strong>Telefono:&nbsp;</strong>{{ eventoData.telefono }}
        </b-list-group-item>
        <b-list-group-item>
          <strong>Direccion:&nbsp;</strong>{{ eventoData.direccion }}
        </b-list-group-item>
        <b-list-group-item>
          <strong>Ciudad:&nbsp;</strong>{{ eventoData.ciudad }}
        </b-list-group-item>
        <b-list-group-item>
          <strong>Organizador:&nbsp;</strong>{{ eventoData.nombre_organizador }}
        </b-list-group-item>
        <b-list-group-item>
          <strong>Telefono:&nbsp;</strong>{{ eventoData.telefono_organizador }}
        </b-list-group-item>
      </b-list-group> -->
    </b-card>
  </b-col>
</template>

<script>
export default {
  components: {},
  props: {
    eventoData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
  },
  data() {
    return {
      listData: [
        { text: "Cras justo odio" },
        { text: "Dapibus ac facilisis in" },
        { text: "Vestibulum at eros" },
      ],
      kitchenSinkList: [
        { text: "Cras justo odio" },
        { text: "Vestibulum at eros" },
      ],
    };
  },
};
</script>
<style>
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.evento-card .card {
  background-color: var(--background-color-tertiary);
  padding: 15px;
  border-radius: 10px;
}
.evento-card .media .badge-primary {
  color: var(--color-secondary);
}
</style>
